const actions = {
    GET_ORDER_REPORT_LIST: "GET_ORDER_REPORT_LIST",
    GET_ORDER_REPORT_LIST_SUCCESS: "GET_ORDER_REPORT_LIST_SUCCESS",
    GET_ORDER_REPORT_LIST_FAILED: "GET_ORDER_REPORT_LIST_FAILED",

    GET_ORDER_REPORT_DETAILS_LIST: "GET_ORDER_REPORT_DETAILS_LIST",
    GET_ORDER_REPORT_DETAILS_LIST_SUCCESS: "GET_ORDER_REPORT_DETAILS_LIST_SUCCESS",
    GET_ORDER_REPORT_DETAILS_LIST_FAILED: "GET_ORDER_REPORT_DETAILS_LIST_FAILED",

    OPEN_ORDER_MODAL: "OPEN_ORDER_MODAL",
    OPEN_ORDER_MODAL_SUCCESS: "OPEN_ORDER_MODAL_SUCCESS",
    CLOSE_ORDER_MODAL: "CLOSE_ORDER_MODAL",

    OPEN_RESEND_MAIL_MODAL: "OPEN_RESEND_MAIL_MODAL",
    CLOSE_RESEND_MAIL_MODAL: "CLOSE_RESEND_MAIL_MODAL",

    RESEND_MAIL: "RESEND_MAIL",
    RESEND_MAIL_SUCCESS: "RESEND_MAIL_SUCCESS",
    RESEND_MAIL_FAILED: "RESEND_MAIL_FAILED",

    resendMail: (data) => ({
        type: actions.RESEND_MAIL,
        payload: { data }
    }),
    resendMailSuccess: (message) => ({
        type: actions.RESEND_MAIL_SUCCESS,
        payload: { message }
    }),
    resendMailFailed: (error) => ({
        type: actions.RESEND_MAIL_FAILED,
        payload: { error }
    }),

    openResendMailModal: (orderID) => ({
        type: actions.OPEN_RESEND_MAIL_MODAL,
        payload: { orderID }
    }),
    closeResendMailModal: () => ({
        type: actions.CLOSE_RESEND_MAIL_MODAL
    }),

    openOrderModal: (orderID) => ({
        type: actions.OPEN_ORDER_MODAL,
        payload: { orderID }
    }),
    openOrderModalSuccess: (data, orderID) => ({
        type: actions.OPEN_ORDER_MODAL_SUCCESS,
        data,
        orderID
    }),
    closeOrderModal: () => ({
        type: actions.CLOSE_ORDER_MODAL
    }),

    getOrderReportList: (data) => ({
        type: actions.GET_ORDER_REPORT_LIST,
        payload: { data }
    }),
    getOrderReportListSuccess: (orderReportListResult) => ({
        type: actions.GET_ORDER_REPORT_LIST_SUCCESS,
        orderReportListResult
    }),
    getOrderReportListFailed: (orderReportListError) => ({
        type: actions.GET_ORDER_REPORT_LIST_FAILED,
        orderReportListError
    }),
    getOrderReportDetails: (orderId) => ({
        type: actions.GET_ORDER_REPORT_DETAILS_LIST,
        payload: { orderId }
    }),
    getOrderReportDetailsSuccess: (orderReportDetailsResult) => ({
        type: actions.GET_ORDER_REPORT_DETAILS_LIST_SUCCESS,
        orderReportDetailsResult
    }),
    getOrderReportDetailsFailed: (orderReportListError) => ({
        type: actions.GET_ORDER_REPORT_DETAILS_LIST_FAILED,
        orderReportListError
    }),
}
export default actions;