import moment from 'moment';
import Modal from './Model';
import Tag from './Tag';
import noImageFound from '../../assets/images/no image available.jpg';
import { twoDigitDecimal } from '../../helpers/commonFunction';
import SimPurchaseActions from '../../redux/simPurchase/actions'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const { getSimTypeData } = SimPurchaseActions;


const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const OrderModalComponent = ({ showViewModal, handleCancel, order }) => {
    const dispatch = useDispatch()
    const { simTypeList } = useSelector((state) => state.simPurchase);

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(() => {
        dispatch(getSimTypeData(sendPlanSimType))
    }, [])

    const orderData = order.length > 0 ? order[0] : new Object();
    const planDetails = orderData.OrderDetails ? JSON.parse(orderData.OrderDetails) : [];

    return (
        <>
            <Modal
                title={`Order #: ${orderData.OrderNumber}`}
                showModal={showViewModal}
                onHide={handleCancel}
                showFooter={false}
                width={700}
            >
                <div className="form-row">
                    <div className='form-row col-md-12'>
                        <div className="col-md-2 mb-3">
                            <div>
                                <a style={Styles.comment}>Order Date</a>
                                <p>{moment(orderData.OrderDate).format(dateFormat)}</p>
                            </div>
                        </div>
                        <div className="col-md-2 mb-3">
                            <div>
                                <a style={Styles.comment} className='text-nowrap'>Grand Total</a>
                                <p>{`£${twoDigitDecimal(orderData.GrandTotal)}`}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div>
                                <a style={Styles.comment} className='text-nowrap'>Coupon Discount</a>
                                <p>{`£${twoDigitDecimal(orderData.CouponDiscount)}`}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div>
                                <a style={Styles.comment}>Net Amount</a>
                                <p>{`£${twoDigitDecimal(orderData.NetAmount)}`}</p>
                            </div>
                        </div>
                        <div className="col-md-2 mb-3">

                            <a style={Styles.comment}>Status</a>
                            <p><Tag title={orderData.OrderStatus} color={`${orderData.OrderStatusID === 21 ? "badge-secondary" : orderData.OrderStatusID === 22 ? 'badge-warning' : orderData.OrderStatusID === 23 ? 'badge-success' : "badge-danger"}`} /></p>

                        </div>
                    </div>
                </div>

                <div className="form-column">
                    <hr style={Styles.hr} />
                    <div className="col-md-12 mb-2 mt-2">
                        <p style={{ fontSize: '18px', color: '#2B3038', fontWeight: 'normal', margin: '-6px -12px -6px -12px' }} className='py-2'>Plan Details</p>
                    </div>
                    <hr style={Styles.hr} />
                </div>


                {
                    planDetails && planDetails.length > 0 && planDetails.map((item, index) => {
                        return <>
                            <div className="form-column" style={{ marginLeft: '-15px' }}>
                                <div className="col-md-12 mt-2">
                                    <p style={Styles.planComment}>Plan Name</p>
                                    <p style={Styles.planName}>{item.PlanName}</p>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-6 pt-3'>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <div>
                                                <a style={Styles.planComment}>Data</a>
                                                <p style={Styles.planData}>{item.PlanData}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Validity</a>
                                                <p style={Styles.planData}>{item.PlanDays}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Country</a>
                                                <p style={Styles.planData}>{item.CountryName}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Plan Price</a>
                                                <p style={Styles.planData}>{`£${twoDigitDecimal(item.TotalAmount)}`}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Aggregator</a>
                                                <p style={Styles.planData}>{item.AggregatorName}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Quantity</a>
                                                <p style={Styles.planData}>{item.Quantity}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>SIM Type</a>
                                                {simTypeList && simTypeList.length > 0 && simTypeList.filter(data => data.ID == item.PlanSIMType).map(simType => (
                                                    <p style={Styles.planData} key={simType.ID}>{simType.Value}</p>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Variant</a>
                                                <p style={Styles.planData}>{item.VariantName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <img src={item.ImageUrl ? `${process.env.REACT_APP_BASEURL}/planImages/${item.ImageUrl}` : noImageFound} style={Styles.planImage} className='img-fluid' />
                                </div>
                            </div>

                            {index + 1 != planDetails.length ? <hr /> : ''}

                        </>
                    })
                }


            </Modal>
        </>
    )
}

const Styles = {
    hr: {
        border: '1px solid',
        margin: 0
    },
    comment: {
        fontSize: '16px',
        color: '#2B3038',
        fontWeight: 'normal'
    },
    planComment: {
        fontSize: '15px',
        color: '#2B3038'
    },
    planName: {
        marginTop: '-18px'
    },
    planData: {
        marginTop: '-6px'
    },
    planImage: {
        width: '250px',
        margin: 'auto',
        display: 'block'
    }
}
export default OrderModalComponent