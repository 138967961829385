import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper'

function* resendMailResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/resendMail', data);
        if (response.Status) {
            yield put(actions.resendMailSuccess(response.Message))
        } else {
            yield put(actions.resendMailFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.resendMailFailed(error));
    }
}

function* getOrderReportListResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/orderReport', data);
        if (response.response.Status) {
            yield put(
                actions.getOrderReportListSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getOrderReportListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getOrderReportListFailed(error));
    }
}

function* getOrderReportDetailsResponse({ payload: { orderId } }) {
    try {
        const response = yield call(addData, '/orderdetails/' + orderId);
        if (response.response.Status) {
            yield put(
                actions.getOrderReportDetailsSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getOrderReportDetailsFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getOrderReportDetailsFailed(error));
    }
}

function* openOrderModalResponse({ payload: { orderID } }) {
    try {
        const response = yield call(addData, '/orderdetails/' + orderID);
        if (response.response.Status) {
            yield put(actions.openOrderModalSuccess(response.response.Data, orderID));
        } else {
            yield put(actions.closeOrderModal());
        }
    } catch (error) {
        yield put(actions.closeOrderModal());
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.RESEND_MAIL, resendMailResponse)]);
    yield all([takeEvery(actions.GET_ORDER_REPORT_LIST, getOrderReportListResponse)]);
    yield all([takeEvery(actions.GET_ORDER_REPORT_DETAILS_LIST, getOrderReportDetailsResponse)]);
    yield all([takeEvery(actions.OPEN_ORDER_MODAL, openOrderModalResponse)]);
}